import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Electron-beam lithography tools`}</h4>
    <p>{`Electron-beam (or "e-beam") lithography tools draw patterns in a substrate using electrons. They are the dominant tools for photomask production, and are also infrequently used for low-volume chip production. Like other maskless lithography tools, e-beam tools can quickly and cheaply make new patterns, but are slow at drawing patterns into wafers, making them ill-suited to mass chip production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      